/* ========================================================================== */
/* =============================== ERROR PAGE =============================== */
/* ========================================================================== */

.error-page {
    color: #B0BEC5;

    .title {
        font-size: 72px;
        margin-bottom: 40px;
    }
}

/* ========================================================================== */
/* ============================= CATEGORY PAGE ============================== */
/* ========================================================================== */

.category-tree {
    .tooltip-inner {
    	max-width: 400px;

    	padding: 15px;
    	padding-bottom: 5px;

    	img, video {
    	    max-width: 100%;
            width:100%;
    	}

    	th {
    	    padding-right: 20px;
    	    vertical-align: top;
    	}

    	td {
    	    text-align: left;
    	    padding-bottom: 10px;
    	}
    }

    .treegrid-parent { display:none; }

    .delete-form { display:inline-block; }
}

/* ========================================================================== */
/* ============================ PLATE INDEX PAGE ============================ */
/* ========================================================================== */

#plate-more-actions {
    padding: 2px 5px;
}

.action-more-menu {
    right: 0;
    left: initial;
    padding: 0;
}

.table-with-dropdown {
    padding-bottom: 70px;
}

/* ========================================================================== */
/* ============================ EDIT PLATE PAGE ============================= */
/* ========================================================================== */

#tag-editor-pane {
    z-index:65500;

    .jsPanel-controlbar { padding-right:5px; }
    .jsPanel-btn-close { display:none !important; }
}

.tag-list {
    .tag-list-title {
	   display: inline-block;
    }

    th, td { padding:0!important; }
    .padding { padding:5px; }
}

.plate-image,
.plate-video {
    width: 100%;
}

#plate-edit-form {
    textarea#notice { resize:vertical; }
    #settingsModal .error {
        display:none;
        background-color:#f2dede;
        background-border:#ebccd1;
        color:#a94442;
        border-radius:4px;
        margin-bottom:20px;
        padding:15px;
        p {
            margin:0;
        }
    }
}

.plate-view {
    position:relative;
    
    .crop-image, .edit-plate-source {
        position:absolute;
        right:10px;
        top:10px;
        z-index:65499;
        color:#000;
        padding:2px 5px 5px;
        background:#FFF;
        box-shadow:3px 0 5px -3px #444;
        border-radius:5px;
        cursor: pointer;
    }
    .crop-image.active, .edit-plate-source.active {
        // background:#FFF;
        // box-shadow:3px 0 5px -3px #444;
        border-radius:0 5px 5px 0;
    }
    
    .crop-image-field-container, .edit-plate-source-field-container {
        display:none;
        position:absolute;
        top:10px;
        right:20px;
        z-index:65498;
    
        .crop-image-field, .edit-plate-source-field {
            background:#FFF;
            box-shadow:0 0 5px #444;
            border-radius:5px 0 5px 5px;
            padding:10px;
    
            .new-crop-loader, .new-source-loader {
                span { display:block; margin-bottom:-3px; }
                img { max-width:100%; }
                display:none;
                text-align:center;
            }
            .new-crop-error, .new-source-error {
                span { color:#FF0000; }
                text-align:center;
            }
        }
    }
    .crop-image {
        z-index:65491;
        right: 50px;
    }
    .crop-image-field-container {
        width: 40% !important;
        z-index:65490;
        right: 60px;
    }
}

#youtube-container {
    .fluid-container {
        margin: 0px auto;
    }

    .fluid-container > div {
        position: relative;
        padding-bottom: 67%;
        height: 0px;

        #video-overlay-mask {
            z-index: 5;
            position: absolute;
            top: 0;
            left:0;
            width: 100%;
            height:100%;
            display: none;
        }
    }

    .fluid-container iframe {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
}

/* hack for youtube player stealing mouse events! */
.prevent-mouse {
  pointer-events: none;
}

#plate-canvas {
    position: relative;

    .tag, .direct-tag {
    	background: transparent;
    	position: absolute;
    	border-radius: 5px;
    	/* hack for when the tags are shown at full screen */
        // z-index: 2147483645;
    	z-index: 65495;
        color:#fff;

        width:38px;
        height:38px;
        cursor:grab;
        cursor: -webkit-grab;
        margin:0;

        .icon {
            position: relative;
            margin:0;
            padding:0;

            .position-helper {
                text-align: center;
            }

            i {
                vertical-align:middle;
            }

            img {
                max-height: 100%;
                max-width: 100%;
                width: auto;
                height: auto;
                margin: auto;
            }

        }
    }
    .ui-draggable-dragging { cursor:grabbing; }

    .tag-popover {
        /* hack for when the tags are shown at full screen */
        // z-index: 2147483647;
        z-index: 65496;
        max-width: inherit;

        .popover-content {
            overflow: hidden;
            padding: 20px 0 0 0;

            span[title] {
                cursor: pointer;
            }

            .popover-wrapper {
                overflow:auto;
                padding: 0 15px 15px;

                .popover-close {
                    position:absolute;
                    top:-2px;
                    right:4px;
                    z-index:65497;
                }

                .popover-hide {
                    position: absolute;
                    top: 1px;
                    right: 16px;
                    z-index: 65497;
                    font-size: 8pt;
                }

                .ui-resizable-e,
                .ui-resizable-s { display:none!important; }

                .plaatverdieping-container {
                    width:100%;
                    img { width:100%; max-width:100%; }
                    video { width:100%; max-width:100%; }
                }
            }
        }
    }
}


/* ========================================================================== */
/* ======================== CREATE/EDIT TAG WINDOW ========================== */
/* ========================================================================== */
.mce-window { z-index:2147483646!important; }
.mce-menu { z-index:2147483647!important; }
#styling .alignment button.active { box-shadow:1px 1px 1px rgba(0, 0, 0, 0.25) inset; background:#dadada; }
#styling .btn.iconpicker-component, #styling .btn.icon-size { border:1px solid #ccc; background:#fff; }
#styling .selectable-icon-sizes { border:1px solid #ccc; padding:5px 15px; border-radius:5px; }
#styling .selectable-icon-sizes i { padding:0 3px; }

/* ========================================================================== */
/* ============================= STYLE TAG TYPE ============================= */
/* ========================================================================== */
#tagtype-edit-form .alignment button.active { box-shadow:1px 1px 1px rgba(0, 0, 0, 0.25) inset; background:#dadada; }

/* ========================================================================== */
/* ======================= CONFIRM DELETE PLATE PAGE ======================== */
/* ========================================================================== */
#plate-delete-form .confirmAlert { display:none; }

/* ========================================================================== */
/* ============================= PLATE SHOW PAGE ============================ */
/* ========================================================================== */
#plate-show {
    .tag, .direct-tag { cursor:pointer; }
    .plate-overlay {
        position:absolute;
        z-index:10;

        &.pos-top-left { top:5px; left:5px; }
        &.pos-top-center { top:5px; left:50%; }
        &.pos-top-center > * { position:relative; left:-50%; }
        &.pos-top-right { top:5px; right:5px; }
        &.pos-bot-left { bottom:5px; left:5px; }
        &.pos-bot-center { bottom:5px; left:50%; }
        &.pos-bot-center > * { position:relative; left:-50%; }
        &.pos-bot-right { bottom:5px; right:5px; }
    }
}

/* ========================================================================== */
/* =============================== ERROR PAGE =============================== */
/* ========================================================================== */
#error-page .error-information h1 small { display:block; margin-top:10px; }
#error-page .error-information > small { display:block; margin-top:50px; padding:20px; }

/* ========================================================================== */
/* ============================ PLATE SHARE POPUP =========================== */
/* ========================================================================== */
#share-box { padding:20px; }

/* ========================================================================== */
/* =============================== MAINTENANCE ============================== */
/* ========================================================================== */
.maintenance-alert h4 { margin-bottom:0; }
.maintenance-alert h4.logged-out-title { display:none; }
.maintenance-alert p { margin-top:10px; }

/* ========================================================================== */
/* ============================== NOTIFICATIONS ============================= */
/* ========================================================================== */
#notification-create-form .radio-inline > span { margin-left:3px; top:4px; position:relative; }